.containerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.mainDiv {
  border-radius: 38px;
  margin-top: 5rem;
  /* background: #f3db16;
  box-shadow: inset 20px 20px 60px #d3bf13, inset -20px -20px 60px #fff719; */
  border-radius: 22px;
  background: linear-gradient(145deg, #ffffff, #d8d8d8);
  box-shadow: 10px 10px 20px #afafaf, -10px -10px 20px #ffffff;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0em 1em 1em 1em;
}

.techno {
  margin-top: 1rem;
  height: auto;
  width: 420px;
}
.techno img {
  width: 100%;
}
.logo {
  height: 70px;
}
.logo img {
  top: -50px;
  width: 150px;
  position: relative;
  border-radius: 50%;
  border: #f3db16 10px solid;
  box-shadow: 5px 5px 15px rgb(48, 48, 48),
    -2px -2px 6px rgba(107, 107, 107, 0.4);
}

.logo {
  top: -3rem;
}

.heading {
  margin-top: 1rem;
  font-weight: 900;
  text-align: center;
}
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 1rem;
  margin-top: 3rem;
}

.container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 95%;
  margin: auto;
  gap: 7px;
}

.container .label {
  font-size: 1rem;
  color: #000;
  font-weight: 600;
  transition: 0.3s;
  pointer-events: none;
}

/* .input {
  font-size: 1rem;
  margin: auto;
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  padding: 20px 9px;
  border-radius: 6px;
  color: #fff;
  background-color: rgb(51, 51, 51);
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 1),
    -1px -1px 6px rgba(255, 255, 255, 0.4);
} */
input,
select {
  background: #ecf0f3;
  padding: 10px !important;
  padding-left: 1.1em !important;
  height: 50px !important;
  font-size: 14px !important;
  border-radius: 1em;
  border: none;
  box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

/* .input:focus {
  border: 2px solid transparent;
  color: #fff;
  box-shadow: 1.5px 1.5px 8px rgba(0, 0, 0, 1),
    -1px -1px 6px rgba(255, 255, 255, 0.4), inset 3px 3px 10px rgba(0, 0, 0, 1),
    inset -1px -1px 6px rgba(255, 255, 255, 0.4);
} */

.input:focus.input::placeholder {
  transition: 0.3s;
  opacity: 0;
}

.input:focus + .label {
  color: #000;
  transition: 0.3s;
  padding-left: 2px;
}

.submitBtn,
.leaderboardBtn {
  color: #090909;
  width: 40%;
  padding: 0.7em 1.7em;
  font-weight: 900;
  text-align: center;
  border-radius: 0.5em;
  background: #e8e8e8;
  border: 1px solid #e8e8e8;
  transition: all 0.3s;
  border: none;
  box-shadow: 6px 6px 12px #606060;
  margin: auto;
}

.submitBtn:hover {
  box-shadow: 3px 3px 8px #606060;
  cursor: pointer;
}
.leaderboardBtn {
  border: none;
  width: 380px;
  background: #e8e8e8;
  border-radius: 10px;
  background: linear-gradient(145deg, #ffffff, #d8d8d8);
  box-shadow: 19px 19px 48px #afafaf, -19px -19px 48px #ffffff;
  background-image: url('https://res.cloudinary.com/dduur8qoo/image/upload/v1681378951/techno_rrafs8.png');
  background-size: 200%;
  background-position: 200%;
  background-repeat: no-repeat;
  transition: 0.8s ease-in-out;
  transition-property: background-position, border, color;
  position: relative;
  margin-top: 1em;
  margin-bottom: 1em;
  z-index: 1;
  height: 3em;
  margin: 2em auto;
}
.leaderboardBtn:hover {
  box-shadow: 5px 5px 8px #7d7d7d;
  color: black;
  background-position: 40%;
  cursor: pointer;
}
.submitBtn:active {
  color: #666;
  box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
}
.submitBtn {
  background-color: #f3db16;
}

/* Responsive */

@media (width<600px) {
  .mainDiv {
    width: 70%;
  }
  .form {
    width: 90%;
    margin: auto;
  }
  .container {
    width: 100%;
  }
  .leaderboardBtn {
    width: 60%;
    margin: 1.4em auto;
  }
  .logo img {
    top: -65px;
    width: 100px;
    position: relative;
    border-radius: 50%;
  }
  .techno {
    width: 90%;
    margin: auto;
  }
}

@media (width<422px) {
  .mainDiv {
    width: 90%;
  }
}
