/* Footer */
.guide {
    max-width: 40vw;
    margin: auto;
    padding: 3rem;
    transform: translateY(50%);
    backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 1);
    border-radius: 50px;
    box-shadow: 35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset -12px -12px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
  }
  
  .guide h3 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  }
  
  .guide p {
    font-size: 1.3rem;
    font-weight: 300;
    margin-bottom: 1rem;
    width: fit-content;
    display: flex;
    align-items: center;
  }
  
  .footer {
    height:5rem;
    width: 100%;
    margin-left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    backdrop-filter: blur(10px);
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 35px 35px 68px 0px rgba(145, 192, 255, 0.5), inset -2px -2px 16px 0px rgba(145, 192, 255, 0.6), inset 0px 11px 28px 0px rgb(255, 255, 255);
    transform: translateY(50%);
    border: none;
    padding: 5rem 2rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .footer-about {
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
  }
  
  .footer-about h3 {
    font-size: 2rem;
    font-weight: 700;
  }
  
  .footer-about p {
    font-size: 1.5rem;
    font-weight: 400;
  }
  
  .footer-social {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .footer-social h3 {
    font-size: 2rem;
    font-weight: 700;
  }
  
  .footer-social--icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 10rem;
  }
  
  .icon {
    font-size: 2rem;
    color:  white;
    position: relative;
    cursor: pointer;
  }
  
  .icon:hover {
    color: skyblue;
  }
  
  .footer-bottom--section {
    padding-top: 0rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 10rem;
  }
  
  @media (max-width: 600px) {
    .guide {
      padding: 10rem 10rem;
    }
  
    .footer {
      padding: 5rem 5rem;
    }
  
    .footer-social--icons {
      width: 15rem;
    }
  
    .icon {
      font-size: 1.5rem;
    }
  
  }