.imgContainer {
  display: flex;
  justify-content: space-evenly;
  margin-right: 3.3;
  column-gap: 1.3rem;
}

.imgDiv {
  box-shadow: -3px -3px 7px #d5c80e73, 2px 2px 5px rgba(234, 223, 17, 0.288);
  backdrop-filter: blur(0px);
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 100%;
  margin-left: 0.4rem;
  margin-right: 0.7rem;
  box-shadow: rgba(237, 214, 8, 0.3) 2px 8px 8px;
}
.technoImg {
  width: 80px;
  height: 80px;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  transition: all 0.3s;
  border-radius: 50px;
  box-shadow: 8px 8px 68px 0px rgba(209, 183, 12, 0.5),
    inset -3px -3px 16px 0px rgba(255, 217, 4, 0.6),
    inset 0px 0px 2 px 0px rgb(255, 255, 255);
}
.technoImg:hover {
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
  box-shadow: #132d8c73 2px 2px 10px, rgba(255, 243, 5, 0.288) 2px 2px 10px;
  box-shadow: 15px 15px 40px 0px rgba(209, 183, 12, 0.5),
    inset -5px -5px 16px 0px rgba(255, 217, 4, 0.6),
    inset 0px 4px 28px 0px rgb(255, 255, 255);
}

@media (width<500px){
  .imgContainer{
    width: 85%;
    margin: auto;
  }
  .technoImg{
    width: 60px;
    height: 60px;
  }
}
@media (width<300px){
  .technoImg{
    width: 50px;
    height: 50px;
  }
}